import { FC, useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { ReusableBanner, ButtonIcon } from '@mch-group/uikit-components'
import { cloudinary } from '@constants'
import { detectDpr, redirectOnCtaUrl } from '@utils'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import dayjs from 'dayjs'
import cssClasses from '@constants/cssClasses'
import { AddEventCalendar } from '@components'
import useTabActive from '@hooks/useTabActive'
import { Media, MediaContextProvider } from '@responsive'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { getSiteLanguage } from '@utils'

const cloudinaryName = getEnvConfig.fe.cloudinary.name

/**
 * Videos will only start downloading after they appear in viewport
 * Videos will only play while they are in viewport
 */

const PromotionalBanner: FC<BannerModuleProps> = (props) => {
  const router = useRouter()
  const ref = useRef<HTMLDivElement>(null)
  const isTabActive = useTabActive()
  const { labels } = useLabelsFromAPI(['AltTags'], getSiteLanguage())
  const [isVideoDownloadTriggered, setIsVideoDownloadTriggered] = useState(false)
  const videoUrl = props.banner_video ? cloudinary.throughCloudfront(`https://res.cloudinary.com/${cloudinaryName}/video/upload/sp_auto/${props.banner_video}.m3u8`) : ''
  let ctaVisibility: React.ComponentProps<typeof ReusableBanner>['buttonVisibility'] | '' = ''
  if (props.cta_visibility === 'always_show') {
    ctaVisibility = 'always'
  } else if (props.cta_visibility === 'hover_show') {
    ctaVisibility = 'hover'
  } else {
    ctaVisibility = 'never'
  }

  // Stopping the video depending on scroll and tab.
  // Works with multiple instances of the Banner Component (each will start/stop accordingly)
  useEffect(() => {
    // This will be removed after UIKIT version upgrade that tells when videos are ready
    if (props.media_priority === 'video') {
      setTimeout(() => {
        const videoPlayer: any = ref.current?.querySelector('.reusable-banner video')
        if (videoPlayer) {
          const playPromise = videoPlayer.play()
          if (playPromise !== undefined && !isTabActive) {
            playPromise.then(() => {
              // Automatic playback started!
              // Show playing UI.
              // We can now safely pause video...
              videoPlayer.pause()
            }).catch(() => {
              // Auto-play was prevented
              // Show paused UI.
            })
          }
        }
      }, 1000)
    }
  }, [isTabActive, props])

  useEffect(() => {
    if (isTabActive) {
      setIsVideoDownloadTriggered(true)
    }
  }, [isTabActive, isVideoDownloadTriggered])

  // For Banner with simple Image, tell it's ready upfront.
  useEffect(() => {
    if (props.media_priority === 'image') {
      typeof props.cbComponentReady === 'function' && props.cbComponentReady()
    }
  }, [props])

  return (
    <div className={`${cssClasses.CMS_MODULE} promotional-banner`} ref={ref}>
      <MediaContextProvider>
        <Media greaterThanOrEqual='tablet'>
          <ReusableBanner
            background={uploadTransformationUrl(props.banner_web_img, 'ar_16:9,q_auto,dpr_1.0,c_fill,w_1905,f_auto', {
              transformUrl: cloudinary.throughCloudfront
            })}
            mediaPriority={props.media_priority === 'video' ? 'video' : 'image'}
            backgroundVideo={isVideoDownloadTriggered && videoUrl || ''}
            eyebrow={props.banner_subtitle}
            headline={props.banner_title}
            isHtml
            primaryButton={{
              label: props.primary_cta_label,
              onClick: () => { redirectOnCtaUrl(props.primary_cta_link, router) }
            }}
            videoOnReady={() => {
              typeof props.cbComponentReady === 'function' && props.cbComponentReady()
            }}
            //@ts-ignore
            shareButtonAction={
              props.show_share_option === 'true'
                ? ['facebook', 'whatsapp', 'linkedin', 'twitter', 'weebo', 'wechat', 'mail', 'link-default']
                : false
            }
            calendarButtonComponent={
              <AddEventCalendar
                title={props.event_name_for_calendar}
                description={props.calendar_details}
                startTime={dayjs(props.start_date_and_time, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')}
                endTime={dayjs(props.end_date_and_time, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')}
                isDisplayCalendarIcon
                text=''
                className='promo-atc calendar-button-visible'
                virtual={props.calendar_link ? true : false}
                virtualUrl={props.calendar_link}
                timezoneId='UTC'
                shareIcon={<ButtonIcon title={props.event_name_for_calendar} icon='calendar' size='lg' variant='fill' />}
                moduleId={props.id}
              />
            }
            //@ts-ignore
            showCalendarButton={
              props.show_add_to_calendar === 'true' && props.start_date_and_time && props.end_date_and_time
            }
            text={props.banner_description}
            buttonVisibility={ctaVisibility}
            playsinline
            a11yData={{
              shareBtn: labels?.AltTags?.generalShareToSocialMedia || 'Share to social media',
              calendarBtn: labels?.AltTags?.generalOpenCalendar || 'Share to social media',
              primaryBtn: ''
            }}
          />
        </Media>
        <Media lessThan='tablet'>
          <ReusableBanner
            background={uploadTransformationUrl(props.banner_mobile_img, `ar_1:1,q_auto,${detectDpr()},c_fill,w_550,f_auto`, {
              transformUrl: cloudinary.throughCloudfront
            })}
            a11yData={{
              shareBtn: labels?.AltTags?.generalShareToSocialMedia || 'Share to social media',
              calendarBtn: labels?.AltTags?.generalOpenCalendar || 'Share to social media',
              primaryBtn: ''
            }}
            mediaPriority={props.media_priority === 'video' ? 'video' : 'image'}
            backgroundVideo={isVideoDownloadTriggered && videoUrl || ''}
            eyebrow={props.banner_subtitle}
            headline={props.banner_title}
            isHtml
            primaryButton={{
              label: props.primary_cta_label,
              onClick: () => { redirectOnCtaUrl(props.primary_cta_link, router) }
            }}
            videoOnReady={() => {
              typeof props.cbComponentReady === 'function' && props.cbComponentReady()
            }}
            //@ts-ignore
            shareButtonAction={
              props.show_share_option === 'true'
                ? ['facebook', 'whatsapp', 'linkedin', 'twitter', 'weebo', 'wechat', 'mail', 'link-default']
                : false
            }
            calendarButtonComponent={
              <AddEventCalendar
                title={props.event_name_for_calendar}
                description={props.calendar_details}
                startTime={dayjs(props.start_date_and_time, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')}
                endTime={dayjs(props.end_date_and_time, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')}
                isDisplayCalendarIcon
                text=''
                className='promo-atc calendar-button-visible'
                virtual={props.calendar_link ? true : false}
                virtualUrl={props.calendar_link}
                timezoneId='UTC'
                shareIcon={<ButtonIcon title={props.event_name_for_calendar} icon='calendar' size='lg' variant='fill' />}
                moduleId={props.id}
              />
            }
            //@ts-ignore
            showCalendarButton={
              props.show_add_to_calendar === 'true' && props.start_date_and_time && props.end_date_and_time
            }
            text={props.banner_description}
            buttonVisibility={ctaVisibility}
            playsinline
          />
        </Media>
      </MediaContextProvider>
    </div>
  )
}

interface BannerModuleProps {
  id: string
  banner_web_img: string
  banner_mobile_img: string
  banner_title: string
  banner_subtitle: string
  primary_cta_label: string
  cta_visibility: string
  primary_cta_link: string
  media_priority: string
  banner_video: string
  show_add_to_calendar: string
  show_share_option: string
  banner_description: string
  start_date_and_time: string
  end_date_and_time: string
  event_name_for_calendar: string
  calendar_details: string
  calendar_link: string,
  cbComponentReady: Function
}

export default PromotionalBanner
