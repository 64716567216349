import PromotionalBanner from '@reusable-modules/PromotionalBanner'
import LazyComponent from '@components/LazyComponent'

const PromotionalBannerComponent = (props) => (
  <LazyComponent
    id={props.id}
    placeholderType={5}
    className='no-margin-top'
    autoStart={false}
  ><PromotionalBanner {...props} />
  </LazyComponent>
)

export default PromotionalBannerComponent